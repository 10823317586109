import { isValidArray } from "@/app/helpers/arrays/array";
import dayjs from "dayjs";

// 24 to 12 Format hours
// Se usa en CardLiveMinuteToMinute.tsx
export const FormatHour = (hour: string) => {
	const auxHour: string[] = hour.split(":");
	let formatHour: string = "";
	if (isValidArray(auxHour)) {
		const difHour = 12 - Number(auxHour[0]) || 0;
		difHour >= 0
			? (formatHour = `${hour} AM`)
			: (formatHour = `${Number(auxHour[0]) - 12}:${auxHour[1]} PM`);
	}
	return formatHour;
};
// Hours to seconds | NO SE USA
export const hourToSeconds = (hour: string) => {
	const hourSplit = hour?.split(":");
	let timeSearchInSeconds: any = "";
	if (isValidArray(hourSplit)) {
		timeSearchInSeconds =
			+hourSplit[0] * 60 * 60 + +hourSplit[1] * 60 + +hourSplit[2];
	}
	return timeSearchInSeconds;
};
// Seconds to Hours
export const secondsToHours = (seconds: number) => {
	const sanitizeSeconds: number = seconds || 0;
	return new Date(sanitizeSeconds * 1000)?.toISOString()?.slice(11, 19);
};
// Seconds to minutes
export const secondsToMinutes = (seconds: number) => {
	if (seconds > 0 && seconds <= 3599) {
		return new Date(seconds * 1000).toISOString().slice(14, 19);
	} else if (seconds >= 3600) {
		return secondsToHours(seconds);
	} else return "00:00";
};

export const getMonth = (month: number): string => {
	const months = [
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	];
	return months[month] ? months[month] : "";
};

export const getHour12Format = (hour: number, minutes: number): string => {
	const parsedMinutes =
		minutes === 0 ? "00" : minutes < 10 ? "0" + minutes : minutes;
	const parsedHour = hour <= 12 ? hour : hour - 12;
	const meridian = hour <= 12 ? " AM" : " PM";
	return parsedHour < 10
		? "0" + parsedHour + ":" + parsedMinutes + meridian
		: parsedHour < 12
		? parsedHour + ":" + parsedMinutes + meridian
		: parsedHour === 12
		? parsedHour + ":" + parsedMinutes + meridian
		: "";
};
/**
 * Formatea los minutos a un string con dos dígitos
 * @param minutes Minutos a formatear
 * @returns Minutos formateados
 */
export const formatMinutes = (minutes: number): string => {
	const parsedMinutes =
		minutes === 0 ? "00" : minutes < 10 ? "0" + minutes : minutes.toString();
	return parsedMinutes;
};

export const parseDateString = (
	dateString: string | Date,
	isDayFirst?: boolean
): string => {
	const dateObj = new Date(dateString);
	const finalDate = isDayFirst
		? dateObj.getDate() +
		  " " +
		  getMonth(dateObj.getMonth()) +
		  ", " +
		  dateObj.getFullYear() +
		  " | " +
		  getHour12Format(dateObj.getHours(), dateObj.getMinutes())
		: getMonth(dateObj.getMonth()) +
		  " " +
		  dateObj.getDate() +
		  ", " +
		  dateObj.getFullYear() +
		  " | " +
		  getHour12Format(dateObj.getHours(), dateObj.getMinutes());
	return dateString ? finalDate : "";
};

export const singleDateString = (dateString: string | Date): string => {
	const dateObj = new Date(dateString);
	const finalDate =
		getMonth(dateObj.getMonth()) +
		" " +
		dateObj.getDate() +
		", " +
		dateObj.getFullYear();
	return dateString ? finalDate : "";
};

/**
 * Parsea una fecha en formato ISO 8601 a un objeto Date con la zona horaria de la Ciudad de México
 * @param dateString Fecha en formato ISO 8601
 * @returns Fecha parseada
 */
export const parseToCST = (dateString: string): Date => {
	const date = new Date(dateString); // Crear un objeto Date a partir del string
	return new Date(
		date.toLocaleString("en-US", { timeZone: "America/Mexico_City" })
	);
};

/**
 * Transforma un string date a formato ISO8601 YYYY-MM-DDThh:mm:ssZ
 * @param dateString String
 * @returns String Regresa una fecha en formato ISO8601
 */
export const parseToISO8601 = (dateString: string): string => {
	const date = new Date(dateString);
	const utcTimeStamp = date.getTime();
	const utcDate = new Date(utcTimeStamp);
	return utcDate.toISOString();
};

// Formatear el tiempo en palabras -> Hace 1 minuto
export const changedDate = (changed: string) => {
	const element = document?.createElement("span");

	function timeFormat(diferencia: number) {
		const seconds = Math.floor(diferencia / 1000);
		if (seconds < 60) {
			return "Hace un momento";
		} else if (seconds < 3600) {
			const minutes = Math.floor(seconds / 60);
			return `Hace ${minutes} ${minutes === 1 ? "minuto" : "minutos"}`;
		} else if (seconds < 86400) {
			const hours = Math.floor(seconds / 3600);
			return `Hace ${hours} ${hours === 1 ? "hora" : "horas"}`;
		} else {
			return singleDateString(new Date(changed));
		}
	}

	function actualizarTexto() {
		const modificado: any = new Date(changed);
		const fechaActual: any = new Date();
		const diferencia = fechaActual - modificado;

		const textoFormateado = timeFormat(diferencia);
		element.textContent = textoFormateado;
	}

	// Actualizar el texto inicialmente
	actualizarTexto();

	// Configurar un intervalo para actualizar el texto cada minuto
	const intervaloActualizacion = setInterval(actualizarTexto, 60000);

	// Devolver el elemento contenedor
	return element;
};

export function generateHourRanges(airtime: string, duration: string | number) {
	// Validar si duration es un string y convertirlo a número si es necesario
	if (typeof duration === "string") {
		duration = parseInt(duration, 10);
		if (isNaN(duration)) {
			throw new Error("La duración no es un número válido.");
		}
	}

	// Obtener la fecha actual con dayjs
	const now = dayjs();

	// Parsear la hora inicial en formato HH:mm:ss
	const horaInicial = dayjs(`${now.format("YYYY-MM-DD")}T${airtime}`);

	// Calcular la hora final sumando la duración en minutos
	const horaFinal = horaInicial.add(duration, "minutes");

	// Formatear las horas en formato de 12 horas
	const horaInicialFormatada = horaInicial.format("HH:mm A");
	const horaFinalFormatada = horaFinal.format("HH:mm A");

	// Crear el string final
	const resultado = `${horaInicialFormatada} - ${horaFinalFormatada}`;

	return resultado;
}

export function secondsToISO8601(seconds: number) {
	const hours = Math.floor(seconds / 3600);
	seconds %= 3600;
	const minutes = Math.floor(seconds / 60);
	seconds %= 60;
	const isoString = `PT${hours}H${minutes}M${seconds}S`;
	return isoString;
}

export function getMonthString(month: string) {
	return {
		enero: "january",
		febrero: "february",
		marzo: "march",
		abril: "april",
		mayo: "may",
		junio: "june",
		julio: "july",
		agosto: "august",
		septiembre: "september",
		octubre: "october",
		noviembre: "november",
		diciembre: "december",
	}[month];
}

export const singleDateStringFirstMonth = (dateString: string): string => {
	const dateObj = parseToCST(dateString);
	const finalDate = `${getMonth(
		dateObj.getMonth()
	)} ${dateObj.getDate()}, ${dateObj.getFullYear()} | ${dateObj.getHours()}:${formatMinutes(
		dateObj.getMinutes()
	)}`;
	return dateString ? finalDate : "";
};
